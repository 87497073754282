<template>
    <router-link :to="'/guide/' + guide.id + '/'" :class="kind">
        <div class="guide" ref="bg">
            <div class="icon">
                <img :src="guide.asset.img" loading="lazy"/>
                <span>
                    {{guide.name}}
                </span>
            </div>

            <div class="list">
                <div v-for="(e, i) in guide.schedule" :key="i" :style="getWidth(e)">
                    <p> {{ e.name }} </p>
                    <span>
                        {{getTime(e.start)}} - {{getTime(e.end)}}
                    </span>
                </div>

            </div>
        </div>
    </router-link>
</template>

<script>
export default {
  name: 'guide',

  props: {
      guide: Object,
      kind: {
        type: String,
        default: () => { return '' }
      }
  },

  components: {
  },

  computed: {
  },

  data: function(){
    return { 
        ready: false,
    }
  },

  methods: {
    getWidth(e){
        const pixelsPerHour = 300; 
        const start = new Date(e.start);
        const end = new Date(e.end);
        const currentTime = new Date();

        if (end <= currentTime) { return {display: 'none'}; }

        const duration = end.getTime() - start.getTime();
        const hours = duration / (1000 * 60 * 60); 
        const width = hours * pixelsPerHour; 
        return {width: width + 'px'};
    },

    getTime(time){ return window.getTime(time) }
  },

  mounted: function(){
    this.$nextTick(() => {
        this.$refs.bg.style.backgroundColor = window.getAssetColor(this.guide.asset)
    })
  }
}
</script>

<style scoped lang="scss">
a {
    text-decoration: none;
    color: white;
}

.guide{
    height: 45px;
    border-bottom: 3px solid white;
    padding: 10px;
    margin: 5;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    .list{
        display: flex;
        flex-direction: row;
        font-size: 9px;
        > div{
            min-width: 60px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            border: 1px solid white;
            border-radius: 3px;
            margin: 0 5px;
            padding: 5px;
            overflow: hidden;
            p{
                padding: 0;
                margin: 0;
                max-height: 70%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }
            span{
                display: block;
                font-weight: bold;

            }
        }

    }
    .icon{
        font-size: 7px;
        min-width: 80px;
        span{
            display: block;
            width: 75%;
            padding: 5px 0;
        }
        img{
            max-width: 70px;
            object-fit: contain;
            padding-right: 10px;
            height: 20px;
            display: block;
        }
    }
}

</style>
